import React from 'react';
import { Flex, Box, Image } from 'rebass/styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from '@reach/router';

// STYLES
import {
  offer_display_item_props,
  offer_icon_container_props,
  offer_icon_background_props,
  icon_props,
  offer_info_container_props,
  border_top_props,
  border_side_props,
  offer_info_title_props,
  offer_info_text_props,
  offer_info_button_props
} from './styles';

// COMPONENTS
import Text from './../../atoms/text';
import Heading from './../../atoms/heading';
import Border from './../../atoms/border';
import Button from './../../atoms/button';

export default function OfferDisplayItem(props) {

  const { side, short_description, title, icon, slug } = props;

  // QUERY

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  return (
    <Flex {...offer_display_item_props(side)}
    data-sal={side === 'left' ? 'slide-right' : 'slide-left'}
    data-sal-duration="600"
    data-sal-delay="0"
    data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
        <Flex {...offer_icon_container_props}>
            <Box sx={{ 
              position: 'absolute',
              right: side === 'left' ? null : 0,
              width: [160, 160, 200],
              height: [160, 160, 200],
              opacity: 1,
              transition: 'opacity 200ms ease-in-out 0.0001s',
              visibility: 'inherit'
            }}>
            </Box>
            <Box {...offer_icon_background_props(side)}
            data-sal={side === 'left' ? 'slide-right' : 'slide-left'}
            data-sal-duration="600"
            data-sal-delay="600"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)"
            onClick={() => navigate(`${slug}`)} style={{ cursor: 'pointer' }}>
              <Image {...icon_props(icon, data)}
              data-sal='fade'
              data-sal-duration="600"
              data-sal-delay="1000"
              data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)" />
            </Box>
        </Flex>
        <Flex {...offer_info_container_props}>
            <Border {...border_top_props(side)} />
            <Border {...border_side_props(side)} />
            <Heading {...offer_info_title_props(side)} onClick={() => navigate(`${slug}`)} style={{ cursor: 'pointer' }}>{title}</Heading>
            <Text {...offer_info_text_props(side)}>{short_description}</Text>
        </Flex>
    </Flex>
  );
}
